import autosize from 'autosize';

const initAll = () => {
  autosize(document.querySelectorAll('textarea'));
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
};

export default { initAll, start };
