import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'selector', 'fieldInput']

  connect() {
    this.refresh();
  }

  refresh() {
    if (this.fieldTarget.checked) {
      this.selectorTarget.classList.remove('d_n');
      this.fieldInputTarget.removeAttribute('disabled');
    } else {
      this.selectorTarget.classList.add('d_n');
      this.fieldInputTarget.setAttribute('disabled', 'disabled');
    }
  }
}
