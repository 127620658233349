// https://stackoverflow.com/a/57991537/2237879

import 'intersection-observer';

const initNode = (node) => {
  const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle('dynamic-sticky_active', e.intersectionRatio < 1),
    { threshold: [1] }
  );

  observer.observe(node);
  node.classList.add('dynamic-sticky_initialized');
};

const initAll = () => {
  const nodes = document.querySelectorAll('.dynamic-sticky:not(.dynamic-sticky_initialized)');
  Array.from(nodes).forEach(node => initNode(node));
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
};

export default { initAll, start };
