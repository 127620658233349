import 'intersection-observer';

const intersectionCallback = (entries) => {
  const entry = entries[0];
  if (!entry) return;

  if (entry.intersectionRatio > 0) {
    if (entry.target.getAttribute('data-loading')) { return; }
    entry.target.setAttribute('data-loading', true);
    entry.target.click();
  }
}

const observer = new IntersectionObserver(intersectionCallback);

const init = () => {
  const node = document.querySelector('.pagy-auto-scroll-link');
  if (!node) return;
  observer.observe(node);
};

const start = () => {
  document.addEventListener('page:load', init);
  document.addEventListener('ajax:success', init);
};

export default { start };
