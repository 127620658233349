import tinymce from 'tinymce/tinymce';

import buildDefault from 'modules/tinymce/builds/Default';
import buildUserPosts from 'modules/tinymce/builds/UserPosts';

import 'modules/tinymce/langs/ru';

import 'tinymce/icons/default';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

import 'tinymce/themes/silver';

require.context(
  '!file-loader?name=js/[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
  true,
  /.*/
);

const initAll = () => {
  const content_css = Array.from(document.querySelectorAll('head > link[rel=stylesheet]')).map(node => node.href).slice(0, 2);
  tinymce.remove();
  tinymce.init({ content_css, language: 'ru', ...buildDefault });
  tinymce.init({ content_css, language: 'ru', ...buildUserPosts });
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
};

export default { initAll, start };
