import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'hidden']

  connect() {
    console.log(this.fieldTarget.value);
    if (this.fieldTarget.value && this.fieldTarget.value !== 'undefined' ) { this.show(); }
  }

  show() {
    this.hiddenTarget.classList.remove('hidden');
  }
}
