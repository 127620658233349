import csrfToken from 'modules/csrf';
import url       from 'modules/url';
import { PageEvent } from 'rails-plus';

const onFetched = (content, node) => {
  const id = node.getAttribute('id');

  if (id) {
    const selector = `#${id}`;
    const cache = document.querySelector('.async-cache');
    cache.innerHTML = content;
    ujs.replace(node, cache.querySelector(selector).outerHTML)
    cache.innerHTML = '';
  } else {
    node.outerHTML = content;
  }

  PageEvent.dispatch(null);
}

const initElement = (node) => {
  node.classList.add('async-block_initialized');

  const headers = {
    'Accept': 'text/html',
    'Content-Type': 'text/html',
    'X-CSRF-Token': csrfToken(),
    'X-Requested-With': 'XMLHttpRequest',
  };

  fetch(url(node.getAttribute('data-url'), 'appHost'), { credentials: 'include', headers })
    .then(response => response.text())
    .then(text => onFetched(text, node))
    .catch(error => console.error(error));
};

const initAll = () => {
  const nodes = document.querySelectorAll('.async-block:not(.async-block_initialized)');
  Array.from(nodes).forEach(node => initElement(node));
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
};

export default { initAll, start };
