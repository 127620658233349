const build = {
  plugins: ' advlist anchor autolink autoresize charmap code codesample emoticons fullscreen help hr image imagetools ' +
           ' insertdatetime link lists media nonbreaking noneditable pagebreak paste preview searchreplace table ' +
           ' template textpattern visualblocks visualchars wordcount ',
  selector: '.tinymce_default',
  menubar: true,
  toolbar: ' | removeformat | fontselect fontsizeselect forecolor | ' +
           ' | bold italic underline strikethrough link image bullist numlist | ' +
           ' | alignleft aligncenter alignright alignjustify | hr pagebreak | fullscreen | ',
  toolbar_mode: 'sliding',
  body_class: 'html-block ff-serif',
  autoresize_bottom_margin: 20,
  max_height: 500,
  min_height: 200,
  font_formats: 'Default=initial; Arial=arial,helvetica,sans-serif; Georgia=georgia,palatino,times;',
  fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
  image_advtab: true,
  image_dimensions: false,
  image_caption: true,
  image_class_list: [
    { title: 'None', value: '' },
    { title: 'Responsive', value: 'w-full' }
  ],
  image_list: [
    { title: 'Иконка сайта', value: '/android-chrome-512x512.png' },
  ],
  images_upload_credentials: true,
  images_upload_url: '/api/by_session/tinymce_attachments',
  relative_urls: false,
  setup: (editor) => {
    editor.ui.registry.addToggleButton('codeformat', {
      tooltip: 'Code',
      icon: 'sourcecode',
      onAction: () => { editor.execCommand('mceToggleFormat', false, 'code'); },
      onSetup: (api) => {
        api.setActive(editor.formatter.match('code'));
        const unbind = editor.formatter.formatChanged('code', api.setActive).unbind;
        return () => { if (unbind) unbind(); };
      }
    });
  }
};

export default build;
