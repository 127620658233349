import MicroModal from 'micromodal';

const init = () => {
  MicroModal.init({
    awaitOpenAnimation: true,
    awaitCloseAnimation: true,
    disableScroll: false,
  });
};

const start = () => {
  document.addEventListener('page:load', init);
};

export default { start };
