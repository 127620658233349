import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'rails-plus/es6/bundles/modernizr';
import 'what-input';
import 'whatwg-fetch';
import 'dom4';
import 'modules/pagy.js.erb';
import 'shareon';
import 'stylesheets';

import {
  Flips,
} from 'rails-plus';

import $                          from 'jquery';
import Rails                      from '@rails/ujs';
import Turbolinks                 from 'turbolinks';
import { Application }            from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import AsyncPartials       from 'modules/AsyncPartials';
import AutosizedTextareas  from 'modules/AutosizedTextareas';
import BrowserUpdate       from 'modules/BrowserUpdate';
import Fileinputs          from 'modules/Fileinputs';
import FlashItems          from 'modules/FlashItems';
import DynamicSticky       from 'modules/DynamicSticky';
import LaddaButtons        from 'modules/LaddaButtons';
import MicroModals         from 'modules/MicroModals';
import PageEvent           from 'modules/PageEvent';
import PagyAutoScroll      from 'modules/PagyAutoScroll';
import PhotoSwipeGallery   from 'modules/PhotoSwipeGallery';
import Select2Mimic        from 'modules/Select2Mimic';
import Select2Selects      from 'modules/Select2Selects';
import SmoothScrollLinks   from 'modules/SmoothScrollLinks';
import TinyMCE             from 'modules/TinyMCE';
import Tooltips            from 'modules/Tooltips';
import ujs                 from 'modules/ujs';

window.$ = $;
window.jQuery = $;
window.PageEvent = PageEvent;
window.ujs = ujs;

AsyncPartials.start();
AutosizedTextareas.start();
BrowserUpdate.start();
DynamicSticky.start();
Fileinputs.start();
FlashItems.start();
Flips.start();
LaddaButtons.start();
MicroModals.start();
PagyAutoScroll.start();
PhotoSwipeGallery.start();
Rails.start();
Select2Mimic.start();
Select2Selects.start();
TinyMCE.start();
Tooltips.start();
Turbolinks.start();

if (!('scrollBehavior' in document.documentElement.style)) { SmoothScrollLinks.start(); }

PageEvent.delegate('turbolinks:load', 'page:load');

const application = Application.start();
const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

if (document.readyState !== 'loading') {
  const event = document.createEvent('Event');
  event.initEvent('DOMContentLoaded', true, true);
  window.document.dispatchEvent(event);
}
