
const initNode = (input) => {
  const label = input.nextElementSibling;
  const labelValue = label.innerHTML;
  input.classList.add('fileinput_initialized');

  input.addEventListener('change', function(e) {
    let fileName = '';

    if (this.files && this.files.length > 1 ) {
      fileName = (this.getAttribute( 'data-multiple-caption' ) || '')
                    .replace('{count}', this.files.length);
    } else {
      fileName = e.target.value.split("\\").pop();
    }

    if (fileName) {
      label.innerHTML = fileName;
    } else {
      label.innerHTML = labelValue;
    }
  });
}

const initAll = () => {
  const nodes = document.querySelectorAll('.fileinput:not(.fileinput_initialized)');
  [...nodes].forEach(node => initNode(node));
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
};

export default { initAll, start };
