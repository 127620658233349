import browserUpdate from 'browser-update';

const init = () => {
  browserUpdate({
    required: { e: -6, f: -6, o: -6, s: 11, c: -6 },
    insecure: true,
    unsupported: true,
    style: 'bottom',
    api: 2020.04,
  });
};

const start = () => {
  document.addEventListener('DOMContentLoaded', init);
};

export default { init, start };
