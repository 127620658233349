import $ from 'jquery';
import Templates from 'modules/select2/templates';
import 'select2';

const initNode = (node) => {
  if (node.classList.contains('flatpickr-monthDropdown-months')) return;
  const options  = {};
  const template = node.getAttribute('data-template');
  const tags     = node.getAttribute('data-tags');
  if (template && Templates.get(template)) { options['templateResult'] = Templates.get(template); }
  if (tags === true) { options['tags'] = true; }
  $(node).select2(options);
};

const initAll = () => {
  const nodes = document.querySelectorAll('select:not(.select2-hidden-accessible)');
  Array.from(nodes).forEach(node => initNode(node));
};

const destroyAll = () => {
  $('select.select2-hidden-accessible').select2('destroy');
};

const start = () => {
  document.addEventListener('page:load', initAll);
  document.addEventListener('ajax:success', initAll);
  document.addEventListener('turbolinks:before-cache', destroyAll);
};

export default { initAll, start };
