const closeBsModals = () => {
  if (!$('.modal.in').length) return;
  $('.modal.in').modal('hide');
}

const closeRemodals = () => {
  if (!$('.remodal.remodal-is-opened').length) return;
  $('.remodal.remodal-is-opened').remodal().close();
  $('.remodal-overlay').remove();
  $('.remodal-wrapper').remove();
  const padding = $('body').css('padding-right');
  $('.remodal-fixed-section').css('padding-right', padding);
  document.documentElement.classList.remove('remodal-is-locked');
}

const append = (selector, content) => {
  $(selector).append(content);
};

const html = (selector, content) => {
  $(selector).html(content);
};

const modal = (selector, content) => {
  $(selector).remove();
  $('body').append(content);
  $(selector).modal('show');
};

const remodal = (selector, content) => {
  $('.remodal-overlay').remove();
  $('.remodal-wrapper').remove();
  $('body').append(content);
  $(selector).last().remodal({ hashTracking: false }).open();
};

const replace = (selector, content) => {
  const $nodes = $(selector);
  $(content).insertAfter($nodes);
  $nodes.hide();
  setTimeout(() => { $nodes.remove(); }, 50);
};

const visit = (url) => {
  window.location = url;
};

export default { closeBsModals, closeRemodals, append, html, modal, remodal, replace, visit };
