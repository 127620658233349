const build = {
  plugins: ' autolink autoresize emoticons fullscreen help hr image imagetools ' +
           ' link lists media nonbreaking noneditable paste wordcount ' +
           ' template ',
  selector: '.tinymce_user_posts',
  menubar: false,
  toolbar: ' | bold italic removeformat | bullist numlist link image media emoticons hr | formatselect | ' +
           ' | alignleft aligncenter alignjustify | fullscreen help | ',
  toolbar_mode: 'sliding',
  body_class: 'html-block ff-serif',
  autoresize_bottom_margin: 20,
  max_height: 500,
  min_height: 200,
  font_formats: 'Default=initial; Arial=arial,helvetica,sans-serif; Georgia=georgia,palatino,times;',
  fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
  image_advtab: true,
  image_dimensions: false,
  image_caption: true,
  image_class_list: [
    { title: 'None', value: '' },
    { title: 'Responsive', value: 'w-full' }
  ],
  image_list: [
    { title: 'Иконка сайта', value: '/android-chrome-512x512.png' },
  ],
  images_upload_credentials: true,
  images_upload_url: '/api/by_session/tinymce_attachments',
  relative_urls: false,
};

export default build;
